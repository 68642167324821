import { confirmationTokenUrlParam } from 'acces-impot-settings-user'
import { MESSAGE_ON_LOAD_URL_PARAM } from 'acces-impot-utils/lib/tracking'
import { ADMIN_DETAILS_ROUTE_QUERY } from '@/components/Admin/constants'
import { ROUTE_NAMES, AUTH_TYPES, AUTH_REFERER_KEY } from '@/constants/route-names'
import { EventBus, EVENTS } from '@/services/event-bus'
import { track, TRACK_CATEGORIES, TRACK_ACTIONS } from '@/services/tracking'
import { waitFor } from '@/helpers/time'
import { preventPwaPrompt } from '@/helpers/pwa'
import { removeQueryFromPath } from '@/helpers/url'

function getScrollPosition(to, from) {
  const isFaq = route => /Faq-/.test(route.name)
  const isWithinFaq = isFaq(from) && isFaq(to)
  if (isWithinFaq) return {}

  if (to.hash) {
    const position = {}
    position.selector = to.hash
    position.offset = { y: to.hash === '#testimonials' ? 40 : 0 }

    if (process.browser && document.querySelector(to.hash)) {
      return position
    }
  }
  return { x: 0, y: 0 }
}

function onFirstBeforeEach(store, route) {
  fetchCurrentUser(store, route)
  preventPwaPrompt()
  store.commit('admin/resetLastVisitedReportsPage')
}

function fetchCurrentUser(store, route) {
  store.commit('account/setIsCurrentUserFetched', false)
  store.dispatch('account/fetchCurrentUser', route.query[confirmationTokenUrlParam])
}

function cleanRoute(route) {
  if (!route.query[confirmationTokenUrlParam]) return false

  Object.assign(route.query, { [confirmationTokenUrlParam]: undefined })
  return true
}

export default context => {
  const { app, store } = context
  const redirectAuthRoute = async routeTo => {
    if (!process.browser) return

    const {
      isRequiringAuthConfirmed,
      isRequiringAuth,
      isAuthForm,
      isAccountSection,
      isAdminSection,
    } = routeTo.meta || {}
    let isUserLoggedIn

    if (isRequiringAuthConfirmed || isRequiringAuth || isAuthForm) {
      await waitFor(store, state => state.account.isCurrentUserFetched)
      isUserLoggedIn = store.getters['account/isUserLoggedIn']
    }
    if ((isRequiringAuthConfirmed || isRequiringAuth) && !isUserLoggedIn) {
      if (process.browser) {
        let refererPath = removeQueryFromPath(routeTo.fullPath, MESSAGE_ON_LOAD_URL_PARAM)

        localStorage.setItem(AUTH_REFERER_KEY, refererPath)
      }
      const query = { ...routeTo.query }

      Object.values(ADMIN_DETAILS_ROUTE_QUERY).forEach(queryParam => {
        delete query[queryParam]
      })
      return app.localeRoute({
        name: ROUTE_NAMES.auth,
        params: { type: AUTH_TYPES.login },
        query,
      })
    }
    const { currentUser } = store.state.account
    const isUserAdmin = store.getters['account/isAdmin']
    const isLoggedInAuthForm = isAuthForm && isUserLoggedIn
    if ((isLoggedInAuthForm || isRequiringAuthConfirmed) && !currentUser.isConfirmed) {
      return app.localeRoute({ name: ROUTE_NAMES.accountNotConfirmed, query: routeTo.query })
    }
    if (
      (isAuthForm && isUserLoggedIn) ||
      (isRequiringAuth && currentUser.isConfirmed) ||
      (!isUserAdmin && isAdminSection) ||
      (isUserAdmin && isAccountSection)
    ) {
      const name = isUserAdmin ? ROUTE_NAMES.admin.index : ROUTE_NAMES.account.index
      return app.localeRoute({ name, query: routeTo.query })
    }
    if (!isAuthForm) localStorage.removeItem(AUTH_REFERER_KEY)
  }

  app.nuxt.defaultTransition.beforeEnter = () => {
    app.i18n.finalizePendingLocaleChange()
    EventBus.$emit(EVENTS.beforeViewEnter)
  }

  let oneTimeBeforeEach = to => {
    onFirstBeforeEach(store, to)
    oneTimeBeforeEach = () => {}
  }

  app.router.beforeEach(async (routeTo, _routeFrom, next) => {
    oneTimeBeforeEach(routeTo)

    const hasCleanedRouteTo = cleanRoute(routeTo)
    const routeToRedirect = await redirectAuthRoute(routeTo)

    if (routeToRedirect && routeToRedirect.name !== routeTo.name) return next(routeToRedirect)

    hasCleanedRouteTo ? next(routeTo) : next()
  })

  app.router.beforeResolve((to, from, next) => {
    if (process.browser && to.name !== from.name) EventBus.$emit(EVENTS.viewLeave)

    next()
  })

  app.router.afterEach((to, from) => {
    if (process.browser && to.name !== from.name) {
      track(TRACK_CATEGORIES.navigation, TRACK_ACTIONS.routeView, { path: to.fullPath })
    }
  })

  app.router.options.scrollBehavior = async (to, from, savedPosition) => {
    if (to.name !== from.name) await app.i18n.waitForPendingLocaleChange()
    if (context.waitForPageFadeOut) await context.waitForPageFadeOut

    return savedPosition || getScrollPosition(to, from)
  }
}
